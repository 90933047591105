import { useRouter } from 'next/router';

import Brand from '../components/basic/Brand';
import Button from '../components/elements/Button';
import Heading2 from '../components/elements/Heading/Heading2';
import Paragraph from '../components/elements/Paragraph';
import BasicLayout from '../components/modules/Layout/BasicLayout';
import Seo from '../components/modules/Seo';
import IllustrationOnoz from '../components/svgs/illustrations/onoz.svg';
import routes from '../config/routes';

export default function Page404(): JSX.Element {
    const router = useRouter();

    return (
        <BasicLayout>
            <Seo title="Page introuvable" url={routes.page404.getUrl()} />
            <div className="m-auto p-4 md:p-0">
                <div className="max-w-full md:w-[500px] text-center">
                    <Brand />
                    <IllustrationOnoz className="mx-auto" />
                    <Heading2>Oups !</Heading2>
                    <Paragraph>Désolé, la page que vous cherchez est introuvable.</Paragraph>
                    <Button className="mt-6" onClick={() => router.push(routes.home.getUrl())}>
                        Retour sur Partager.io
                    </Button>
                </div>
            </div>
        </BasicLayout>
    );
}
